import { Box, Divider, Grid, Paper, styled, SwipeableDrawer, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import PropTypes from 'prop-types'
import { type ConnectedYahooFantasyPlayer } from 'features/fantasy-team/models/yahooConnectedTeam'
import { PlayerImage } from 'components/shared-components/PlayerImage'
import type YahooFantasyPlayerStatistics from 'features/fantasy-team/models/yahooFantasyPlayerStatistics'
import PlayerStatisticGridItem from 'components/shared-components/PlayerStatisticSection'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import CircularContentLoader from 'components/circular-content-loader/CircularContentLoader'
import FantaHockeyScore from './FantaHockeyScore'
import PlayerFlag from './PlayerFlag'

interface PlayerStatsSwipeableDrawerProps {
  open: boolean
  height?: number | string
  onOpen: () => void
  player: ConnectedYahooFantasyPlayer
  onClose: () => void
}

const Puller = styled('div')(({ theme }) => ({
  width: 35,
  height: 7,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
  ...theme.applyStyles('dark', {
    backgroundColor: grey[900]
  })
}))

const PlayerStatsSwipeableDrawer: React.FC<PlayerStatsSwipeableDrawerProps> = ({ open, onClose, onOpen, height, player }) => {
  const { isFetching, data: playerStatistics, refetch: getPlayerStatistics } = useQuery<YahooFantasyPlayerStatistics>({
    queryKey: [ApiQueryKeys.GetYahooFantasyPlayerStatistics],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetYahooFantasyPlayerStatistics](player.playerId)).data
    },
    enabled: !(player.playerId === 0)
  })

  const { data: fantaHockeyScore, refetch: getFantaHockeyPlayerScore } = useQuery<number>({
    queryKey: [ApiQueryKeys.GetPlayerFantaHockeyScore],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetPlayerFantaHockeyScore](player.playerId)).data
    },
    enabled: !(player.playerId === 0)
  })

  React.useEffect(() => {
    const fetchPlayerStatistics = async (): Promise<void> => {
      if (player.playerId !== 0) {
        await getPlayerStatistics()
        await getFantaHockeyPlayerScore()
      }
    }
    void fetchPlayerStatistics()
  }, [player])
  const mapPositionCodeToPosition = (position: string | undefined): string => {
    if (position == null) {
      return ''
    }
    switch (position) {
      case 'C':
        return 'C'
      case 'L':
        return 'LW'
      case 'R':
        return 'RW'
      case 'D':
        return 'D'
      case 'G':
        return 'G'
      default:
        return ''
    }
  }

  return <SwipeableDrawer
    anchor="bottom"
    open={open}
    onClose={onClose}
    onOpen={onOpen}
    disableBackdropTransition={true}
    disableDiscovery={true}
    PaperProps={{
      sx: {
        height: height ?? '90dvh',
        overflow: 'auto'
      }
    }}
    disableSwipeToOpen={false}
    ModalProps={{
      keepMounted: true
    }}
  >
    <Box height={16} width={4}>
      <Puller onClick={onClose} />
    </Box>
    {isFetching
      ? <CircularContentLoader size={'36px'} thickness={4} marginTop={'64px'} textAlignment={'center'} />
      : <Box
        sx={{
          position: 'relative', // Set position to relative for the parent Box
          px: 2,
          pb: 2,
          height: '100%',
          overflow: 'auto'
        }}
      >
        {/* Blurred Background Image */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            backgroundSize: '75% 75%',
            backgroundImage: player.fantasyScore > 0 ? `url(${player?.teamLogoUrl})` : '',
            backgroundBlendMode: 'lighten',
            backgroundRepeat: 'no-repeat',
            zIndex: -1
          }}
        />
        {/* Content */}
        <Paper sx={{ boxShadow: 'none', mt: 6 }}>
        {player.fantasyScore === 0
          ? <Box p={2} textAlign="center">
              <PlayerImage
                height="calc(1dvw + 96px)"
                width="calc(1dvw + 96px)"
                src={player?.playerHeadshotUrl}
              />
              <Box my={1} />
              <Typography variant="h6">{player.name} does not have any statistics yet</Typography>
            </Box>
          : <Box>
            <Grid
              container
              p={2}
              spacing={3} // Increase spacing between grid items
              direction="row"
              alignItems="flex-start" // Align items at the start of each column
              justifyContent="space-evenly" // Spread out content evenly across the container
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography fontSize={'calc(0.5vw + 18px)'} fontWeight="700" textAlign="center" mt={1}>
                  #{playerStatistics?.sweaterNumber} | {player?.name}
                </Typography>

              </Grid>
            </Grid>
            <Grid
              mt={1}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Grid item>
                <FantaHockeyScore value={fantaHockeyScore ?? 0} size={50} thickness={2} />
              </Grid>

              <Grid item>
              <Box mt={-3} />
              <img src='./assets/images/Yahoo!-Logo.wine.png' alt='yahoo' height={64} />
              <Typography variant="subtitle1" align="center" fontWeight={700} mt={-3}>
                {player.fantasyScore.toFixed(2)} Pts
              </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              p={2}
              spacing={3} // Increase spacing between grid items
              direction="row"
              justifyContent={'center'}
              alignItems="center" // Align items at the start of each column
            >
              {/* Left Column */}
              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Typography><PlayerFlag country={playerStatistics?.birthCountry}/></Typography>
                <PlayerImage
                  height="calc(1dvw + 96px)"
                  width="calc(1dvw + 96px)"
                  src={player?.playerHeadshotUrl}
                />
              </Grid>

              {/* Right Column */}
              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                xl={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                {/* Position */}
                <Grid item>
                  <Typography>
                    <span style={{ fontWeight: 700, marginRight: '4px' }}>Position:</span>
                    {mapPositionCodeToPosition(playerStatistics?.position)}
                  </Typography>
                </Grid>

                {/* Age */}
                <Grid item>
                  <Typography align="center">
                    <span style={{ fontWeight: 700, marginRight: '4px' }}>Age:</span>
                    {playerStatistics?.age}
                  </Typography>
                </Grid>

                {/* Height */}
                <Grid item>
                  <Typography align="center">
                    <span style={{ fontWeight: 700, marginRight: '4px' }}>Height:</span>
                    {playerStatistics?.heightInFeetAndInches}
                  </Typography>
                </Grid>

                {/* Weight */}
                <Grid item>
                  <Typography align="center">
                    <span style={{ fontWeight: 700, marginRight: '4px' }}>Weight:</span>
                    {playerStatistics?.weightInPounds} lbs
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Core Stats Section */}
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.goals} statisticTitle="Goals" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.assists} statisticTitle="Assists" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.points} statisticTitle="Points" maxWidth='50px' />
            </Grid>
            <Divider />
            {/* Core Stats Section */}
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.shots} statisticTitle="Shots" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.plusMinus} statisticTitle="+/-" statisticTooltip='Plus Minus' maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.penaltyMinutes} statisticTitle="PIM" statisticTooltip='Penalty Minutes' maxWidth='50px' />
            </Grid>
            <Divider />
            {/* Core Stats Section */}
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.faceoffWinPercentage} statisticTitle="FO %" statisticTooltip='Face Off Percentage' maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.shootingPercentage} statisticTitle="SH %" statisticTooltip='Shooting Percentage' maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.gameWinningGoals} statisticTitle="GWG" statisticTooltip='Game Winning Goals' maxWidth='50px' />
            </Grid>
            <Divider />
            {/* Core Stats Section */}
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.firstGoals} statisticTitle="FG" statisticTooltip="First Goals" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.giveaways} statisticTitle="GA" statisticTooltip="Give Aways" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.hits} statisticTitle="Hits" maxWidth='50px' />
            </Grid>
            <Divider />
            {/* Core Stats Section */}
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.individualFenwickFor} statisticTooltip='Individual Fenwick For' statisticTitle="IFF" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.individualCorsiFor} statisticTooltip='Individual Corsi For' statisticTitle="ICF" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.powerPlayGoals} statisticTooltip='Power Play Goals' statisticTitle="PP Goals" maxWidth='80px' />
            </Grid>
            {/* Core Stats Section */}
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.overtimeGoals} statisticTooltip='Overtime Goals' statisticTitle="OT Goals" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.takeaways} statisticTitle="Take Aways" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.gamesPlayed} statisticTitle="Games Played" maxWidth='50px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.goalsPer60?.toFixed(2)} statisticTitle="Goals Per 60 min" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.assistsPer60?.toFixed(2)} statisticTitle="Assists Per 60 min" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.pointsPer60?.toFixed(2)} statisticTitle="Points Per 60 min" maxWidth='50px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.blockedShots} statisticTitle="Total Blocked Shots" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.blockedShotsPer60} statisticTitle="Blocked Shots Per 60 Min" maxWidth='75px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.shotAttemptsBlocked} statisticTitle="Shot Attempts Blocked" maxWidth='50px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={`${playerStatistics?.timeOnIcePerGame?.toFixed(2) ?? 0} Min`} statisticTooltip='Time On Ice Per Game' statisticTitle="TOI Per Game" maxWidth='40px' />
              <PlayerStatisticGridItem statistic={`${playerStatistics?.evTimeOnIcePerGame?.toFixed(2) ?? 0} Min`} statisticTooltip='Even Strength Time on Ice Per Game' statisticTitle="EV TOI Per Game" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={`${playerStatistics?.ppTimeOnIcePerGame?.toFixed(2) ?? 0} Min`} statisticTooltip='Power Play Time on Ice Per Game' statisticTitle="PP TOI Per Game" maxWidth='50px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={`${playerStatistics?.timeOnIcePerShift?.toFixed(2) ?? 0} Sec`} statisticTooltip='Time On Ice Per Shift' statisticTitle="TOI Per Shift" maxWidth='40px' />
              <PlayerStatisticGridItem statistic={`${playerStatistics?.otTimeOnIcePerOtGame?.toFixed(2) ?? 0} Min`} statisticTooltip='Overtime Time On Ice Per Overtime Game' statisticTitle="OT TOI Per OT Game" maxWidth='60px' />
              <PlayerStatisticGridItem statistic={`${playerStatistics?.shTimeOnIcePerGame?.toFixed(2) ?? 0} Min`} statisticTooltip='Shorthanded Time On Ice Per Game' statisticTitle="SH TOI Per Game" maxWidth='60px' />
            </Grid>
          </Box>}
        </Paper>
      </Box>
    }
  </SwipeableDrawer>
}

PlayerStatsSwipeableDrawer.displayName = 'PlayerStatsSwipeableDrawer'
PlayerStatsSwipeableDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default React.memo(PlayerStatsSwipeableDrawer)
