import { Box, List, ListItem, ListItemButton, ListItemText, Paper, Checkbox, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { type YahooLeagueTeam } from 'features/fantasy-team/models/yahooFantasyLeagueTeam'
import React from 'react'

// Move TeamImg outside the component
const TeamImg = styled('img')({
  width: 40,
  height: 40,
  objectFit: 'contain',
  marginRight: 16,
  borderRadius: 50
})

interface Props {
  isLoading: boolean
  teams: YahooLeagueTeam[]
  createSelectedTeam: (team: YahooLeagueTeam) => void
}

const YahooFantasyTeamSelector: React.FC<Props> = ({ isLoading, teams, createSelectedTeam }): JSX.Element => {
  const [selected, setSelected] = React.useState<YahooLeagueTeam | null>(null)

  const selectTeamOnClick = React.useCallback(
    (team: YahooLeagueTeam) => {
      createSelectedTeam(team)
      setSelected(team)
    },
    [createSelectedTeam] // Include dependencies
  )

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {isLoading &&
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
         <CircularProgress color='info' />
        </Box>}
        <List component="nav">
          {teams.map((team) => (
            <ListItem key={team.teamId} disablePadding divider>
              <ListItemButton
                onClick={() => { selectTeamOnClick(team) }}
                sx={{
                  backgroundColor: selected?.teamId === team.teamId ? 'rgba(0, 0, 0, 0.1)' : 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  padding: { xs: '12px', md: '16px' }
                }}
              >
                <Checkbox
                  edge="start"
                  color="success"
                  checked={selected?.teamId === team.teamId}
                  tabIndex={-1}
                />
                <Box ml={2} />
                <TeamImg
                  src={team.teamLogos.teamLogo.url}
                  alt={`${team.name} logo`}
                />
                <ListItemText
                  primary={team.name}
                  secondary={team.managerList.managers.map((manager) => manager.nickname).join(', ')}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  )
}

export default YahooFantasyTeamSelector
