import { useFantaHockeyContext } from 'providers/AuthProvider'
import React from 'react'
import { Navigate } from 'react-router-dom'

const AuthenticatedRoute: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const fantaHockeyAuth = useFantaHockeyContext()
  const isAuthenticated = fantaHockeyAuth.isAuthenticated

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />
}

export default AuthenticatedRoute
