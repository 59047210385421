import { CA, US, SE, FI, RU, SV, CZ, CH, DE, LV, DK, AT, BY, NO, UA, FR, GB, AU, IT, SI, BG } from 'country-flag-icons/react/3x2'
import React from 'react'

interface PlayerFlagProps {
  country: string | undefined
}

const PlayerFlag: React.FC<PlayerFlagProps> = ({ country }): JSX.Element => {
  if (country == null) {
    return <></>
  }
  const flagSize = { width: 36, height: 36 }

  switch (country) {
    case 'CAN':
      return <CA {...flagSize} />
    case 'USA':
      return <US {...flagSize} />
    case 'SWE':
      return <SE {...flagSize} />
    case 'FIN':
      return <FI {...flagSize} />
    case 'RUS':
      return <RU {...flagSize} />
    case 'SVK':
      return <SV {...flagSize} />
    case 'CZE':
      return <CZ {...flagSize} />
    case 'SUI':
      return <CH {...flagSize} />
    case 'GER':
      return <DE {...flagSize} />
    case 'LAT':
      return <LV {...flagSize} />
    case 'DEN':
      return <DK {...flagSize} />
    case 'AUT':
      return <AT {...flagSize} />
    case 'BLR':
      return <BY {...flagSize} />
    case 'NOR':
      return <NO {...flagSize} />
    case 'UKR':
      return <UA {...flagSize} />
    case 'FRA':
      return <FR {...flagSize} />
    case 'GBR':
      return <GB {...flagSize} />
    case 'AUS':
      return <AU {...flagSize} />
    case 'ITA':
      return <IT {...flagSize} />
    case 'SLO':
      return <SI {...flagSize} />
    case 'BLU':
      return <BG {...flagSize} />
    default:
      return <></>
  }
}

export default React.memo(PlayerFlag)
