import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useDocumentTitle } from '@uidotdev/usehooks'
import React from 'react'
import ConnectYahooFantasyTeamStepper from './ConnectYahooFantasyTeamStepper'
import CenterBox from 'components/helpers/CenterBox'
import { GppGood } from '@mui/icons-material'
import DynamicIcon from 'components/helpers/DynamicIcon'
import PageBase from 'components/page-title/PageBase'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { ApiMutationKeys, ApiMutationService } from 'services/mutation/apiMutationService'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import { useNavigate } from 'react-router'

const ConnectMyFantasyTeam: React.FC = () => {
  useDocumentTitle('FantaHockey | Connect My Fantasy Team')
  const queryClient = useQueryClient()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const { mutateAsync: deleteYahooFantasyTeamAsync } = useMutation<boolean>({
    mutationKey: [ApiMutationKeys.DeleteYahooFantasyTeamMutation],
    mutationFn: async () => {
      return (await ApiMutationService[ApiMutationKeys.DeleteYahooFantasyTeamMutation]()).data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys[ApiQueryKeys.IsUserConnectedToYahooQuery]] })
    }
  })

  const { isLoading: isFetching, data: isConnectedToYahooResult } = useQuery<boolean>({
    queryKey: [ApiQueryKeys.IsUserConnectedToYahooQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.IsUserConnectedToYahooQuery]()).data
    }
  })

  const disconnectYahooFantasyTeam = async (): Promise<void> => {
    await deleteYahooFantasyTeamAsync()
    snackbar.openSnackbar('Disconnected Fantasy Team', 'success')
  }

  if (isFetching) {
    return (
      <CenterBox>
        <Box mt={2} />
        <CircularProgress color="info" />
      </CenterBox>
    )
  }

  return (
    <PageBase title={'Connect My Team'}>
      <Box mt={2} />
      {!isFetching && (isConnectedToYahooResult ?? false)
        ? (
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Box
            p={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box my={2}>
              <DynamicIcon Icon={GppGood} color="success" />
            </Box>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                You have connected your Fantasy Team
              </Typography>
            </Box>
            <Box mt={3} />
            <Box display={'flex'} flexDirection={'column'}>
            <Button
                variant="contained"
                color="success"
                onClick={() => { navigate('/my-fantasy-team', { replace: true }) }}
              >
                Go to Yahoo! Fantasy Team
              </Button>
              <Box mt={2} />
              <Button
                variant="contained"
                color="error"
                onClick={() => { void disconnectYahooFantasyTeam() }}
              >
                Disconnect Yahoo! Fantasy Team
              </Button>
            </Box>
          </Box>
        </Grid>
          )
        : (
        <ConnectYahooFantasyTeamStepper
            onCompletedCallback={async () => {
              await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.IsUserConnectedToYahooQuery] })
              snackbar.openSnackbar('Connected to Fantasy Team', 'success')
              navigate('/my-fantasy-team', { replace: true })
            }}
        />
          )}
    </PageBase>
  )
}

ConnectMyFantasyTeam.displayName = 'ConnectMyFantasyTeam'
export default ConnectMyFantasyTeam
