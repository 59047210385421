import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import './AppHeader.scss'
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, SwipeableDrawer, Toolbar, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useFantaHockeyContext } from 'providers/AuthProvider'
import AppMenuList from 'components/app-drawer/AppDrawer'
import { httpAxiosInstance } from 'services/http/axiosInstance'
import { Logout, Settings } from '@mui/icons-material'
import styled from '@emotion/styled'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import { isMobile } from 'react-device-detect'

const AppHeader: React.FC = React.memo(() => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [name, setName] = React.useState<string>('')
  const anchor = 'left'
  const navigate = useNavigate()
  const context = useFantaHockeyContext()
  const { openSnackbar } = useSnackbar()

  const toggleMenuDrawer = React.useCallback((isOpen: boolean): void => {
    setIsMenuOpen(isOpen)
  }, [])

  React.useEffect(() => {
    if (context.isAuthenticated) {
      setName(context?.name ?? '')
    }
  }, [context.name])

  const appLogout = async (): Promise<void> => {
    context.setSplashScreen(true)
    await httpAxiosInstance.post('.auth/logout', context.email)
    context.logout()
    navigate('/login', { replace: true })
    setTimeout(() => {
      context.setSplashScreen(false)
    }, 750)
    openSnackbar('Logged Out', 'success')
  }

  const getAppDrawer = React.useCallback((): JSX.Element => {
    return <>
            <React.Fragment key={anchor}>
                <SwipeableDrawer
                    disableSwipeToOpen={!!isMobile}
                    disableBackdropTransition={!!isMobile}
                    hideBackdrop={!!isMobile}
                    disableDiscovery={!isMobile}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                      width: 500,
                      flexShrink: 0,
                      '& .MuiDrawer-paper': { width: 'auto', boxSizing: 'border-box' }
                    }}
                    key={anchor}
                    onClose={() => { toggleMenuDrawer(false) }}
                    anchor={anchor}
                    onOpen={() => { toggleMenuDrawer(true) }}
                    open={isMenuOpen}
                >
              <Box
                    mt={4}
                    role="presentation"
                    onClick={() => { toggleMenuDrawer(false) }}
                    onKeyDown={() => { toggleMenuDrawer(false) }}>
                  <AppMenuList anchor={anchor} toggleMenuDrawer={toggleMenuDrawer} />
              </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </>
  }, [isMenuOpen, toggleMenuDrawer, anchor])

  const getProfileNameFirstLetter = (): string | null => {
    if (context.isAuthenticated) {
      return name.charAt(0).toUpperCase() ?? ''
    }
    return null
  }

  const getFirstName = (): string | null => {
    if (context.isAuthenticated) {
      return name.split(' ')[0] ?? ''
    }
    return null
  }

  const MenuAvatar = styled(Avatar)({
    width: 32,
    height: 32
  })

  const profileButton = (): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>): void => {
      setAnchorEl(event.currentTarget)
    }, [anchorEl])
    const handleClose = React.useCallback((): void => {
      setAnchorEl(null)
    }, [anchorEl])
    if (!context.isAuthenticated) return <></>
    return (
      <React.Fragment>
        <Box sx={{ flexGrow: 1 }}>
          <Tooltip title="Profile & Settings">
            <IconButton
              onClick={handleClick}
              size="medium"
              sx={{ ml: 2, float: 'right' }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <MenuAvatar>{getProfileNameFirstLetter()}</MenuAvatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: 300,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => { navigate('/my-profile', { replace: true }) }}>
          <MenuAvatar>{getProfileNameFirstLetter()}</MenuAvatar>
          <Typography sx={{ ml: 1 }}>{getFirstName()}&apos;s Profile</Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => { navigate('/settings', { replace: true }) }}>
            <ListItemIcon>
              <Settings fontSize="small" htmlColor={'white'} />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={() => { void appLogout() }}>
            <ListItemIcon>
              <Logout fontSize="small" htmlColor={'white'} />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }

  const MenuButton = (): JSX.Element => {
    if (!context.isAuthenticated) return <></>
    return (
    <Tooltip title="Menu">
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                onClick={() => { toggleMenuDrawer(!isMenuOpen) }}
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon />
                            </IconButton>
        </Tooltip>
    )
  }
  return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }} padding={4}>
                <AppBar
                    position="fixed"
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 100, backgroundColor: 'rgb(19, 47, 76)', display: 'grid' }}>
                    <Toolbar>
                        <MenuButton/>
                        {getAppDrawer()}
                        {context.isAuthenticated && (
                          <IconButton sx={{ borderRadius: 0, padding: 1 }} onClick={() => { navigate('/home') }}>
                          <Typography fontFamily={'Spantaran'} fontSize={28} ml={2}>
                              FantaHockey
                          </Typography>
                        </IconButton >
                        )}
                        {!context.isAuthenticated && (
                         <Typography fontFamily={'Spantaran'} fontSize={28} ml={2}>
                            FantaHockey
                        </Typography>
                        )}
                        {profileButton()}
                    </Toolbar>
                </AppBar>
            </Box>
        </React.Fragment>
  )
})

AppHeader.displayName = 'AppHeader'
export default AppHeader
