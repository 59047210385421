import React from 'react'
import { useDocumentTitle } from '@uidotdev/usehooks'
import PageBase from 'components/page-title/PageBase'
import { type TopPlayerDashboardResult, type HomeDashboardViewModel } from 'features/home-dashboard/models/homeDashboardModels'
import { Box, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Skeleton, styled, Typography } from '@mui/material'
import { PlayerImage } from 'components/shared-components/PlayerImage'
import { TeamImage } from 'components/shared-components/TeamImage'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryService, ApiQueryKeys } from 'services/query/apiQueryService'

interface PlayerListHomeDashboardTopLeaderListProps {
  topPlayerDashboardResult: TopPlayerDashboardResult[] | undefined
  isLoading: boolean
}

const HomeDashboardTopLeaderList: React.FC<PlayerListHomeDashboardTopLeaderListProps> = ({ topPlayerDashboardResult, isLoading }) => {
  const formatTopResultType = (typeOfTopResult: string): string => {
    switch (typeOfTopResult) {
      case 'Points':
        return 'Points'
      case 'Goals':
        return 'Goals'
      case 'Assists':
        return 'Assists'
      case 'SavePercentage':
        return '% SV'
      case 'Wins':
        return 'Wins'
      case 'TimeOnIcePerGame':
        return 'Min Per Game'
    }
    return typeOfTopResult
  }

  const TopPlayerLoader = (): JSX.Element => (
    <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2 }}>
      <Box p={2}>
      <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Skeleton variant="circular" width={50} height={50} />
          </ListItemAvatar>
          <Box ml={1} />
          <ListItemText
            primary={
              <>
                <Skeleton variant='text' width='100%' height={20} />
                <Box my={1} />
                <Skeleton variant="rectangular" width='100%' height={50} />
              </>
            }
          />
        </ListItem>
      </Box>
    </List>
  )

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2 }}>
      {isLoading && <TopPlayerLoader/>}
      {!isLoading && topPlayerDashboardResult?.map((player, index) => (
        <>
        <ListItem alignItems="flex-start" key={index}>
          <ListItemAvatar>
            <PlayerImage alt={player.name} src={player.playerImageUrl} />
          </ListItemAvatar>
          <Box ml={1} />
          <ListItemText
            primary={
              <>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="text.primary"
                >
                  {player.name}
                </Typography>
              </>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="subtitle2"
                  fontWeight={600}
                  sx={{ color: 'text.primary', display: 'inline' }}
                >
                   {player.topResultValue} {formatTopResultType(player.typeOfTopResult)}
                </Typography>
              </React.Fragment>
            }
          />
          <TeamImage src={player.teamImageUrl} />
        </ListItem>
        {index < topPlayerDashboardResult.length - 1 && <Divider component={'li'} />}
        </>
      ))}
    </List>
  )
}

const Home: React.FC = React.memo(() => {
  useDocumentTitle('FantaHockey | Home')
  const [homeDashboard, setHomeDashboard] = React.useState<HomeDashboardViewModel | undefined>(undefined)
  const { isLoading, data } = useQuery<HomeDashboardViewModel>({
    queryKey: [ApiQueryKeys[ApiQueryKeys.GetDashboardHomeQuery]],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetDashboardHomeQuery]()).data
    }
  })

  React.useEffect(() => {
    if (data != null) {
      setHomeDashboard(data)
    }
  }, [data])

  const DashboardHeaderTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontFamily: 'Spantaran',
    marginBottom: 8,
    fontSize: 'calc(16px + 0.5vw)',
    fontWeight: theme.typography.fontWeightRegular
  }))

  return (
    <PageBase title={'Home'}>
      <Grid p={4} spacing={4} container>
        <Grid item xs={12} md={4} lg={4}>
          <DashboardHeaderTypography variant='h5'>Top Forwards By Points</DashboardHeaderTypography>
          <HomeDashboardTopLeaderList topPlayerDashboardResult={homeDashboard?.topForwardsByPoints} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DashboardHeaderTypography variant='h5'>Top Defense By Points</DashboardHeaderTypography>
          <HomeDashboardTopLeaderList topPlayerDashboardResult={homeDashboard?.topDefenseByPoints} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DashboardHeaderTypography variant='h5'>Top Forwards By Goals</DashboardHeaderTypography>
          <HomeDashboardTopLeaderList topPlayerDashboardResult={homeDashboard?.topForwardsByGoals} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DashboardHeaderTypography variant='h5'>Top Forwards By Assists</DashboardHeaderTypography>
          <HomeDashboardTopLeaderList topPlayerDashboardResult={homeDashboard?.topForwardsByAssists} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DashboardHeaderTypography variant='h5'>Top Forwards By TOI Per Game</DashboardHeaderTypography>
          <HomeDashboardTopLeaderList topPlayerDashboardResult={homeDashboard?.topForwardByTimeOnIcePerGame} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DashboardHeaderTypography variant='h5'>Top Goalies By Wins</DashboardHeaderTypography>
          <HomeDashboardTopLeaderList topPlayerDashboardResult={homeDashboard?.topGoalieByWins} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DashboardHeaderTypography variant='h5'>Top Goalies By Save Percentage</DashboardHeaderTypography>
          <HomeDashboardTopLeaderList topPlayerDashboardResult={homeDashboard?.topGoalieBySavePercentage} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DashboardHeaderTypography variant='h5'>Top Goalies By Saves</DashboardHeaderTypography>
          <HomeDashboardTopLeaderList topPlayerDashboardResult={homeDashboard?.topGoalieBySaves} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DashboardHeaderTypography variant='h5'>Top Goalies By Shutouts</DashboardHeaderTypography>
          <HomeDashboardTopLeaderList topPlayerDashboardResult={homeDashboard?.topGoalieByShutouts} isLoading={isLoading} />
        </Grid>
      </Grid>
    </PageBase>
  )
})

Home.displayName = 'Home'
export default Home
