import { type CircularProgressProps, Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import React from 'react'

const FantaHockeyScore = (props: CircularProgressProps & { value: number }): JSX.Element => {
  const getBackgroundColorFromValue = (value: number): 'error' | 'warning' | 'success' => {
    if (value < 5) {
      return 'error'
    } else if (value < 7) {
      return 'warning'
    }
    return 'success'
  }

  return (
    <Tooltip title={'FantaHockey Player Score'} placement="bottom">
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={props.size} color={getBackgroundColorFromValue(props.value)} variant="determinate" value={props.value * 10}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ color: 'white' }}
        >{props.value.toFixed(2)}</Typography>
      </Box>
    </Box>
    </Tooltip>
  )
}

export default React.memo(FantaHockeyScore)
