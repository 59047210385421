import { Grid, Box, Typography, Tooltip } from '@mui/material'
import React from 'react'

interface PlayerStatisticGridItemProps {
  statisticTitle: string
  statisticTooltip?: string
  statistic: number | string | undefined
  maxWidth?: string
}

const PlayerStatisticGridItem: React.FC<PlayerStatisticGridItemProps> = ({ statistic, statisticTitle, statisticTooltip, maxWidth }): JSX.Element => {
  return <>
        <Grid
            item
            sm={4}
            xs={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            direction="column"
            alignItems="center"
            justifyContent="center"
            >
            <Tooltip placement='top' disableFocusListener title={statisticTooltip}>
              <Box maxWidth={maxWidth} textAlign={'center'}>
                  <Typography variant='subtitle2' fontWeight="700">{statisticTitle}</Typography>
                      <Typography>{statistic}</Typography>
              </Box>
            </Tooltip>
            </Grid>
            </>
}

export default React.memo(PlayerStatisticGridItem)
