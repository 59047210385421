import { Box, Divider, Grid, Paper, styled, SwipeableDrawer, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import PropTypes from 'prop-types'
import { type ConnectedYahooFantasyPlayer } from 'features/fantasy-team/models/yahooConnectedTeam'
import { PlayerImage } from 'components/shared-components/PlayerImage'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import CircularContentLoader from 'components/circular-content-loader/CircularContentLoader'
import { type YahooFantasyGoalieStatistics } from 'features/fantasy-team/models/yahooFantasyGoalieStatistics'
import PlayerFlag from './PlayerFlag'
import FantaHockeyScore from './FantaHockeyScore'
import PlayerStatisticGridItem from 'components/shared-components/PlayerStatisticSection'

interface GoalieStatsSwipeableDrawerProps {
  open: boolean
  height?: number | string
  onOpen: () => void
  goalie: ConnectedYahooFantasyPlayer
  onClose: () => void
}

const Puller = styled('div')(({ theme }) => ({
  width: 35,
  height: 7,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
  ...theme.applyStyles('dark', {
    backgroundColor: grey[900]
  })
}))

const GoalieStatsSwipeableDrawer: React.FC<GoalieStatsSwipeableDrawerProps> = ({ open, onClose, onOpen, height, goalie }) => {
  const { isFetching, data: goalieStatistics, refetch: getGoalieStatistics } = useQuery<YahooFantasyGoalieStatistics>({
    queryKey: [ApiQueryKeys.GetYahooFantasyGoalieStatistics],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetYahooFantasyGoalieStatistics](goalie?.playerId)).data
    },
    enabled: !(goalie?.playerId === 0)
  })

  const { data: fantaHockeyScore, refetch: getFantaHockeyGoalieScore } = useQuery<number>({
    queryKey: [ApiQueryKeys.GetGoalieFantaHockeyScore],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetGoalieFantaHockeyScore](goalie.playerId)).data
    },
    enabled: !(goalie.playerId === 0)
  })

  React.useEffect(() => {
    const fetchPlayerStatistics = async (): Promise<void> => {
      if (goalie.playerId !== 0) {
        await getGoalieStatistics()
        await getFantaHockeyGoalieScore()
      }
    }
    void fetchPlayerStatistics()
  }, [goalie])

  return <SwipeableDrawer
    anchor="bottom"
    open={open}
    onClose={onClose}
    onOpen={onOpen}
    disableBackdropTransition={true}
    disableDiscovery={true}
    PaperProps={{
      sx: {
        height: height ?? '90dvh',
        overflow: 'auto'
      }
    }}
    disableSwipeToOpen={false}
    ModalProps={{
      keepMounted: false
    }}
  >
    <Box height={16} width={4}>
      <Puller onClick={onClose} />
    </Box>
    {isFetching
      ? <CircularContentLoader size={'36px'} thickness={4} marginTop={'64px'} textAlignment={'center'} />
      : <Box
      sx={{
        position: 'relative', // Set position to relative for the parent Box
        px: 2,
        pb: 2,
        height: '100%',
        overflow: 'auto'
      }}
    >
      {/* Blurred Background Image */}
      <Box
        sx={{
          position: 'absolute', // Position the background Box absolutely
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundSize: '100% 100%',
          backgroundImage: `url(${goalie?.teamLogoUrl})`,
          backgroundBlendMode: 'lighten',
          backgroundRepeat: 'no-repeat',
          zIndex: -1 // Ensure the background is behind the content
        }}
      />
      {/* Content */}
      <Paper sx={{ boxShadow: 'none', mt: 8 }}>
      {goalie.fantasyScore === 0
        ? <Box p={2} textAlign="center">
              <PlayerImage
                height="calc(1dvw + 96px)"
                width="calc(1dvw + 96px)"
                src={goalie?.playerHeadshotUrl}
              />
              <Box my={1} />
              <Typography variant="h6">{goalie.name} does not have any statistics yet</Typography>
            </Box>
        : <Box>
            <Grid
              container
              p={2}
              spacing={3} // Increase spacing between grid items
              direction="row"
              alignItems="flex-start" // Align items at the start of each column
              justifyContent="space-evenly" // Spread out content evenly across the container
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography fontSize={'calc(0.5vw + 18px)'} fontWeight="700" textAlign="center" mt={1}>
                  #{goalieStatistics?.sweaterNumber} | {goalieStatistics?.fullName}
                </Typography>

              </Grid>
            </Grid>
            <Grid
              mt={1}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Grid item>
                <FantaHockeyScore value={fantaHockeyScore ?? 0} size={50} thickness={2} />
              </Grid>

              <Grid item>
              <Box mt={-3} />
              <img src='./assets/images/Yahoo!-Logo.wine.png' alt='yahoo' height={64} />
              <Typography variant="subtitle1" align="center" fontWeight={700} mt={-3}>
                {goalie.fantasyScore.toFixed(2)} Pts
              </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              p={2}
              spacing={3} // Increase spacing between grid items
              direction="row"
              justifyContent={'center'}
              alignItems="center" // Align items at the start of each column
            >
              {/* Left Column */}
              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Typography><PlayerFlag country={goalieStatistics?.birthCountry}/></Typography>
                <PlayerImage
                  height="calc(1dvw + 96px)"
                  width="calc(1dvw + 96px)"
                  src={goalie?.playerHeadshotUrl}
                />
              </Grid>

              {/* Right Column */}
              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                xl={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                {/* Position */}
                <Grid item>
                  <Typography>
                    <span style={{ fontWeight: 700, marginRight: '4px' }}>Position:</span>
                    G
                  </Typography>
                </Grid>

                {/* Age */}
                <Grid item>
                  <Typography align="center">
                    <span style={{ fontWeight: 700, marginRight: '4px' }}>Age:</span>
                    {goalieStatistics?.age}
                  </Typography>
                </Grid>

                {/* Height */}
                <Grid item>
                  <Typography align="center">
                    <span style={{ fontWeight: 700, marginRight: '4px' }}>Height:</span>
                    {goalieStatistics?.heightInFeetAndInches}
                  </Typography>
                </Grid>

                {/* Weight */}
                <Grid item>
                  <Typography align="center">
                    <span style={{ fontWeight: 700, marginRight: '4px' }}>Weight:</span>
                    {goalieStatistics?.weightInPounds} lbs
                  </Typography>
                </Grid>
              </Grid>
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={goalieStatistics?.wins} statisticTitle="Wins" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.losses} statisticTitle="Losses" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.overtimeLosses} statisticTitle="OT Losses" maxWidth='75px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={goalieStatistics?.savePercentage?.toFixed(3)} statisticTitle="SV%" statisticTooltip="Save Percentage" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.goalsAgainstAverage?.toFixed(2)} statisticTitle="GAA" statisticTooltip="Goals Against Average" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.goalsAgainst} statisticTitle="GA" statisticTooltip="Goals Against" maxWidth='50px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={goalieStatistics?.saves} statisticTitle="Saves" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.shotsAgainst} statisticTitle="SA" statisticTooltip="Shots Against" maxWidth='75px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.shutouts} statisticTitle="Shutouts" maxWidth='50px' />
            </Grid>
            <Divider />
            </Grid>
      </Box>}
      </Paper>
    </Box>}
  </SwipeableDrawer>
}

GoalieStatsSwipeableDrawer.displayName = 'GoalieStatsSwipeableDrawer'
GoalieStatsSwipeableDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  goalie: PropTypes.any.isRequired
}

export default React.memo(GoalieStatsSwipeableDrawer)
