import React, { useCallback } from 'react'
import { Delete } from '@mui/icons-material'
import { Avatar, Box, Button, Grid, Paper, Typography } from '@mui/material'
import { useDocumentTitle } from '@uidotdev/usehooks'
import AppPageTitle from 'components/app-page-title/AppPageTitle'
import { useFantaHockeyContext } from 'providers/AuthProvider'
import { useSnackbar } from 'hooks/notification/UseSnackbar'

const MyProfile: React.FC = (): JSX.Element => {
  // Set the document title
  useDocumentTitle('FantaHockey | My Profile')
  const { openSnackbar } = useSnackbar()
  // Destructure the auth context
  const { name, email, getProfileNameFirstLetter, logout } = useFantaHockeyContext()

  // Memoized logout handler
  const deleteAccount = useCallback(() => {
    logout()
    openSnackbar('Account deleted', 'success')
  }, [logout])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppPageTitle title="Profile" />
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ margin: 2, padding: 4 }} elevation={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {/* Avatar */}
            <Avatar
              sx={{ width: 50, height: 50 }}
              alt={name}
            >
              {getProfileNameFirstLetter()}
            </Avatar>

            {/* Name & Email */}
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Typography variant="h6" color="text.primary">
                {name}
              </Typography>
              <Typography variant="subtitle2" color="text.primary">
                {email}
              </Typography>
            </Box>

            {/* Delete Account Button */}
            <Button
              variant="contained"
              color="error"
              startIcon={<Delete />}
              onClick={deleteAccount}
            >
              Delete Account
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

MyProfile.displayName = 'MyProfile'
export default MyProfile
